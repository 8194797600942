import { CurrencyPipe, DatePipe } from '@angular/common';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NIGHTWEB_APP_CONSTANT, USER_STATUS } from '../../../constants';
import { FormatPhone, NightwebUtil } from '../../../utils';
import { ActionItemType, InputType, TableConfig } from '../interface';
import * as moment from 'moment';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit {
  filterBy: string[] = [];
  key = '';
  defaultDatePipeFormat: string = NIGHTWEB_APP_CONSTANT.defaultDatePipeFormat;
  shortDateTimeFormat: string = NIGHTWEB_APP_CONSTANT.shortDateTimeFormat;
  defaultTimeFormat: string = NIGHTWEB_APP_CONSTANT.defaultTimeFormat;
  inputType = InputType;
  actionItemType = ActionItemType;

  @Input() notFoundMessage!: string;
  @Input() tableConfig!: TableConfig;
  @Input() headerElements: any;
  @Input() bodyElements: any;
  @Input() tableDatas: any;
  @Input() tableHeadClass: string =
    'bg-gray-light text-gray-dark position-sticky top-0';
  @Input() tableWrapperClass: string =
    'table-responsive scrollbar-primary thin ';
  @Input() actionItems: any;
  @Input() query: any;
  @Input() set keyword(key: any) {
    this.key = key;
    this.filterBy = this.bodyElements
      .filter((x: any) => x.value)
      .map((y: any) => y.value);
  }
  @Input() module: any;
  @Input() iconName: any;
  @Input() loading: boolean = true;
  @Input() currentCheckedIndex!: number | null;
  @Input() convertToLocal: boolean = true;
  @Input() isProcessing: boolean = false;

  @Output() sendEvent = new EventEmitter();
  @Output() sendPage = new EventEmitter();
  @Output() onScrollToBottom = new EventEmitter<any>();

  @ViewChild('tableElement', { static: false }) public tableElement!: ElementRef;

  @HostListener('window:scroll', ['$event'])
  onScroll(event: any): void {
    const nativeElement = this.tableElement.nativeElement;
    if (nativeElement.scrollTop) {
      const height = Math.round(
        event.target.clientHeight + nativeElement.scrollTop + 1
      );
      if (height >= nativeElement.scrollHeight) {
        this.onScrollToBottom.emit({ scrolledToBottom: true });
      }
    }
  }

  skeletonTheme = NIGHTWEB_APP_CONSTANT.skeletonLoaderConfig;
  tableDataLoader = Array(3).fill(0);
  constructor(
    private datePipe: DatePipe,
    private _formatPhone: FormatPhone,
    private nightwebUtil: NightwebUtil,
    private currencyPipe: CurrencyPipe
  ) {}

  ngOnInit(): void {
    this.notFoundMessage = this.notFoundMessage ?? `No data.`;
  }

  getValueByField(field: any, obj: any) {
    let value: any;
    if (Array.isArray(field.value)) {
      if (field?.objectKey) {
        field.value.forEach((item: any) => {
          value = {
            ...value,
            [item]: obj[`${field.objectKey}`][`${item}`],
          };
        });
      } else {
        field.value.forEach((item: any) => {
          value = {
            ...value,
            [item]: obj[`${item}`],
          };
        });
      }
    } else {
      value =
        obj[field.value] === ''
          ? ''
          : obj[field.value] || obj[field.value] == 0
          ? obj[field.value]
          : '';
    }
    if (field.concatString) value = value.toString().concat(field.concatString);
    if (field.dataType) {
      switch (field.dataType) {
        case 'status':
          const status = this.nightwebUtil.toTitleCase(value);
          // value = this.nightwebUtil.toTitleCase(value);
          value =
            obj.activationStatus.toLowerCase() === USER_STATUS.ACTIVATED
              ? `
            <span class="badge rounded-pill bg-opacity-25 bg-success text-success">
              ${status}
            </span>
            `
              : obj.activationStatus === 'activation in progress'
              ? `
            <span class="badge rounded-pill bg-opacity-25 bg-info text-info">
              ${status}
            </span>
            `
              : obj.activationStatus === USER_STATUS.DEACTIVATED
              ? `
            <span class="badge rounded-pill bg-opacity-25 bg-danger text-danger">
              ${status}
            </span>
            `
              : `
            <span>
              ${status}
            </span>
            `;
          break;
        case 'currency':
          value = this.currencyPipe.transform(value);
          break;
        case 'date':
          value =
            value === ''
              ? 'N/A'
              : this.datePipe.transform(value, this.defaultDatePipeFormat);
          break;
        case 'dateTime':
          value =
            value === ''
              ? 'N/A'
              : this.datePipe.transform(
                  value,
                  this.shortDateTimeFormat,
                  '+0000'
                );
          break;
        case 'time':
          value =
            value === ''
              ? 'N/A'
              : this.datePipe.transform(value, this.defaultTimeFormat);
          break;
        case 'phone':
          value = this._formatPhone.phoneNumber(value);
          break;
        case 'contact':
          value = this.nightwebUtil.formatContact(value);
          break;
        case 'device':
          value = this.nightwebUtil.formatLinkedDevice(value);
          break;
        case 'displayName':
          value = this.nightwebUtil.getDisplayName(value);
          break;
        case 'updated':
          value = this.nightwebUtil.formatUpdatedBy(value);
          break;
        case 'created':
          if (value) {
            value = this.convertToLocal
            ? this.nightwebUtil.formatCreatedBy(value)
            : moment(value).utc().format('MM/DD/yyyy hh:mm a');
          } 
          else return 'N/A';
          break;
        case 'address':
          value = this.nightwebUtil.formatAddress(value);
          break;
        case 'array':
          value = this.nightwebUtil.getValue(
            obj[field.objectKey] ? obj[field.objectKey] : obj,
            field.value
          );
          break;
        case 'object':
          if (field.pipe) {
            value = obj[field.objectKey]
              ? this._formatPhone.phoneNumber(obj[field.objectKey][field.value])
              : 'N/A';
          } else {
            value = obj[field.objectKey]
              ? obj[field.objectKey][field.value]
              : 'N/A';
          }
          break;
        case 'percent':
          value = value ? value.toString().concat('%') : 'N/A';
          break;
        case 'lteStrength':
          value = this.getLTEStrength(value);
          break;
        case 'boolean':
          if (field.value === 'isEmergencyState') {
            value = value
              ? "<i class='fas fa-circle text-danger'></i>"
              : "<i class='fas fa-circle text-primary'></i>";
          } else value = value ? 'Yes' : 'No';
          break;
        case 'module':
          value = this.nightwebUtil.formatModule(value);
          break;
        case 'eventType':
          value = this.nightwebUtil.formatEventType(value);
          break;
        case 'adminDemographic':
          value = this.nightwebUtil.getDemographic(value, field);
          break;
        case 'string':
          value = !value || value === 'undefined' ? 'N/A' : value;
          break;
        default:
          break;
      }
    }
    return value;
  }

  getLTEStrength(value: any) {
    switch (value) {
      case 0:
        return 'Low';
      case 1:
        return 'Mid';
      case 2:
        return 'Good';
      case 3:
        return 'Excellent';
      default:
        return 'N/A';
    }
  }

  change(event: any, data: any, index: number) {
    this.currentCheckedIndex = index;
    this.emitEvent({
      action: 'change',
      event,
      object: data,
    });
  }

  emitEvent(data: any) {
    this.sendEvent.emit(data);
  }

  navigate(field: any, data: any) {
    if (field?.navigation) {
      this.emitEvent({
        action: 'navigation',
        event,
        object: data,
      });
    }
  }

  performAction(data: any, eventName?: string, event?:any) {
  
    switch (eventName) {
      case 'editModule':
        this.emitData(data, 'edit');
        break;
      case 'deleteModule':
        this.emitData(data, 'delete');
        break;
      case 'linkUserModule':
        this.emitData(data, 'linkUserModule');
        break;
      case 'userActivationModule':
        this.emitData(data, 'userActivationModule');
        break;
      case 'editConfiguration':
        this.editConfiguration(data);
        break;
      case 'configureColumn':
        this.configureColumn(data);
        break;
      case 'checkbox':
        this.emitData(data, 'checkbox')
        break;
      default:
        break;
    }
  }

  emitData(data: any, action: string) {
    this.emitEvent({
      action,
      event,
      object: data,
    });
  }

  edit(data: any) {
    this.emitEvent({
      action: 'edit',
      event,
      object: data,
    });
  }

  delete(data: any) {
    this.emitEvent({
      action: 'delete',
      event,
      object: data,
    });
  }

  editConfiguration(data: any) {
    this.emitEvent({
      action: 'editConfiguration',
      event,
      object: data,
    });
  }

  configureColumn(data: any) {
    this.emitEvent({
      action: 'configureColumn',
      event,
      object: data,
    });
  }

  getActionLabel(item: any, data: any) {
    const logginedUser = this.nightwebUtil.decodeToken(
      this.nightwebUtil.getLoggedInUser()
    );
    switch (item?.eventChecker) {
      case 'Link/Unlink User':
        if (!data?.activeUser) {
          return 'Link User';
        } else {
          return 'Unlink User';
        }
      case 'Edit Configuration Devices':
        if (data?.activeUser) {
          return 'Edit Configuration';
        }
        return '';
      case 'Activate/Deactivate User':
        if (
          !data?.superRoleId &&
          data?.organizations[0]?.roleId !==
            logginedUser?.organization?.role?.id
        ) {
          if (data?.activationStatus.toLowerCase() === USER_STATUS.ACTIVATED) {
            return 'Deactivate';
          } else if (data?.activationStatus === USER_STATUS.DEACTIVATED) {
            return 'Activate';
          } else return '';
        } else return '';
      default:
        return '';
    }
  }

  updateTable(type: string, data: any, index: number) {
    switch (type) {
      case 'add':
        this.tableConfig.tableDatas.unshift(data);
        break;
      case 'update':
        if (data.updatedData.demographic) {
          const { firstName, middleName, lastName } = data.updatedData.demographic;
          this.tableConfig.tableDatas[index] = { ...this.tableConfig.tableDatas[index], ...data.updatedData, displayName: `${firstName} ${middleName} ${lastName}` };
        } else this.tableConfig.tableDatas[index] = { ...this.tableConfig.tableDatas[index], ...data.updatedData };
        break;
      case 'remove':
        this.tableConfig.tableDatas.splice(index, 1);
    }
  }
}
