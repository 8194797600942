import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { GoogleMapsModule } from '@angular/google-maps';
import { AppComponent } from './app.component';

import * as interceptors from './interceptors';
import * as components from './components';
import * as directives from './directives';
import * as services from './services';
import * as guards from './guards';
import * as pipes from './pipes';
import * as utils from './utils';

import { MdbDropdownModule } from 'mdb-angular-ui-kit/dropdown';
import { MdbCollapseModule } from 'mdb-angular-ui-kit/collapse';
import { MdbSelectModule } from 'mdb-angular-ui-kit/select';
import { MdbDatepickerModule } from 'mdb-angular-ui-kit/datepicker';
import { MdbAutocompleteModule } from 'mdb-angular-ui-kit/autocomplete';
import { MdbAccordionModule } from 'mdb-angular-ui-kit/accordion';
import { MdbSidenavModule } from 'mdb-angular-ui-kit/sidenav';
import { MdbScrollbarModule } from 'mdb-angular-ui-kit/scrollbar';
import { MdbModalModule } from 'mdb-angular-ui-kit/modal';
import { MdbNotificationModule } from 'mdb-angular-ui-kit/notification';
import { MdbPopconfirmService } from 'mdb-angular-ui-kit/popconfirm';
import { MdbTabsModule } from 'mdb-angular-ui-kit/tabs';
import { MdbTimepickerModule } from 'mdb-angular-ui-kit/timepicker';
import { MdbTooltipModule } from 'mdb-angular-ui-kit/tooltip';

import { NgxBootstrapIconsModule } from 'ngx-bootstrap-icons';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { icons, NightwebUtil } from './utils';
import { CurrencyPipe, DatePipe, PercentPipe } from '@angular/common';
import { CodeInputModule } from 'angular-code-input';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { MdbFileUploadModule } from 'mdb-angular-file-upload';

export const options: Partial<null | IConfig> | (() => Partial<IConfig>) = null;
@NgModule({
  declarations: [
    AppComponent,
    components.LayoutMainComponent,
    components.LayoutPublicComponent,
    components.SearchComponent,
    components.DashboardComponent,
    components.LoginComponent,
    components.SidebarComponent,
    components.DeviceComponent,
    components.HeaderComponent,
    components.AutocompleteComponent,
    components.SelectComponent,
    components.PaginationComponent,
    components.InputComponent,
    components.EndUserComponent,
    components.TableComponent,
    components.LoaderComponent,
    components.PopConfirmComponent,
    components.SkeletonLoaderComponent,
    components.RadioComponent,
    components.CheckboxComponent,
    components.NotesComponent,

    components.AddEditDeviceModalComponent,
    components.AddEditUserModalComponent,
    components.AddEditOrganizationModalComponent,
    components.AddEditAdministratorModalComponent,
    components.VerificationPinModalComponent,
    components.ForgotPasswordModalComponent,
    components.LinkDeviceModalComponent,
    components.ConfigurationModalComponent,
    components.ConfirmationModalComponent,
    components.LinkUserModalComponent,
    components.AssignPermissionModalComponent,
    components.UploadModalComponent,
    components.AudioRequestModalComponent,
    components.ConfigureColumnModalComponent,

    components.UserDetailComponent,
    components.OrganizationComponent,
    components.MyProfileComponent,
    components.ProfileInformationComponent,
    components.ChangePasswordComponent,
    components.ResetPasswordComponent,
    components.AdministratorComponent,
    components.RolesAndPermissionComponent,
    components.ActivityLogComponent,
    components.LogoutComponent,
    components.GoogleMapComponent,
    components.NotificationsComponent,
    components.MigrateEndUsersComponent,
    components.AddEditEnduserScrollableFormComponent,

    directives.DebounceClickDirective,

    pipes.CustomFilterPipe,
    pipes.FormatPhoneNumberPipe,
    pipes.TrimCharacters
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    GoogleMapsModule,
    MdbDropdownModule,
    MdbCollapseModule,
    MdbSelectModule,
    MdbDatepickerModule,
    MdbAutocompleteModule,
    MdbAccordionModule,
    MdbSidenavModule,
    MdbScrollbarModule,
    MdbModalModule,
    MdbNotificationModule,
    MdbTabsModule,
    AngularEditorModule,
    NgxBootstrapIconsModule.pick(icons),
    NgxMaskModule.forRoot(),
    NgxSkeletonLoaderModule,
    CodeInputModule,
    MdbTooltipModule,
    MdbTimepickerModule,
    MdbFileUploadModule
  ],
  providers: [
    DatePipe,
    CurrencyPipe,
    PercentPipe,
    NightwebUtil,
    utils.FormatPhone,
    utils.DateUtility,
    guards.AuthGuard,
    guards.RoleGuard,
    services.AuthService,
    services.UserService,
    services.ToasterService,
    services.OrganizationService,
    services.AdminService,
    services.RoleService,
    services.DataService,
    services.ActivityLogService,
    services.LookupService,
    services.MigrationService,
    services.LayoutService,
    services.NightwebService,
    services.DownloadService,
    MdbPopconfirmService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: interceptors.HeaderInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: interceptors.ServerErrorInterceptor,
      multi: true
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
