<div class="row bg-gray-lighter" *ngIf="enduserSideNav">
    <div class="col-3 scrollable-form-nav bg-white pt-3 fixed">
        <div class="back-text" (click)="sendData()">
            <span>&lt;  </span><span>Back</span>
        </div>
        <ol>
            <li *ngFor="let sideNav of enduserSideNav; let i = index" (click)="onNavSelected(i, '#' + sideNav.value)" [class.active]="i === selectedNavIndex">{{ sideNav?.label }}</li>
        </ol>
    </div>
    <div class="col-1/2">&nbsp;</div>
    <div class="col-6 p-3 scrollable-form-container">
        <form [formGroup]="userForm" autocomplete="off">
            <div id="basicInformation" class="mb-3">
                <p class="fw-bold">Basic Information</p>
                <div formGroupName="demographic">
                    <div class="row mb-1">
                        <div class="col-4 d-flex align-items-center justify-content-end">
                            <label class="text-end">First Name</label>
                        </div>
                        <div class="col-8">
                            <app-input [name]="'firstName'" [type]="'text'" [control]="demographic.controls['firstName']"
                                [submitted]="submitted" [required]="true">
                            </app-input>
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-4 d-flex align-items-center justify-content-end">
                            <label class="text-end">Middle Name</label>
                        </div>
                        <div class="col-8">
                            <app-input [name]="'middleName'" [type]="'text'" [control]="demographic.controls['middleName']"
                                [submitted]="submitted">
                            </app-input>
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-4 d-flex align-items-center justify-content-end">
                            <label class="text-end">Last Name</label>
                        </div>
                        <div class="col-8">
                            <app-input [name]="'lastName'" [type]="'text'" [control]="demographic.controls['lastName']"
                                [submitted]="submitted">
                            </app-input>
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-4 d-flex align-items-center justify-content-end">
                            <label class="text-end">Date of Birth</label>
                        </div>
                        <div class="col-8">
                            <mdb-form-control>
                                <input mdbInput [mdbDatepicker]="dob" type="text" class="form-control" id="dob"
                                    formControlName="dob" />
                                <mdb-datepicker-toggle [mdbDatepicker]="dob"></mdb-datepicker-toggle>
                                <mdb-datepicker [inline]="true" [format]="dateFormat" [maxDate]="maxDate" #dob></mdb-datepicker>
                            </mdb-form-control>
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-4 d-flex align-items-center justify-content-end">
                            <label class="text-end">Address</label>
                        </div>
                        <div class="col-8">
                            <app-input [name]="'addressLine1'" [type]="'text'" [control]="home.controls['addressLine1']"
                                [submitted]="submitted" [required]="true" [placeholder]="'Address Line 1'">
                            </app-input>
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-4 d-flex align-items-center justify-content-end">
                            <label class="text-end"></label>
                        </div>
                        <div class="col-3">
                            <app-input [name]="'city'" [type]="'text'" [control]="home.controls['city']"
                                [submitted]="submitted" [required]="true" [placeholder]="'City'">
                            </app-input>
                        </div>
                        <div class="col-3">
                            <app-autocomplete [form]="userForm"
                                [control]="userForm.controls['demographic'].get('address')?.get('home')?.get('state')"
                                [data]="states" [name]="'label'" [value]="'value'" [filterKeys]="['value']"
                                [placeholder]="'State'" (emitSelectedValue)="receiveAutocompleteValue($event)">
                            </app-autocomplete>
                        </div>
                        <div class="col-2">
                            <app-input [name]="'zip'" [type]="'text'" [control]="home.controls['zip']" [mask]="'0*'"
                                [maxLength]="5" [submitted]="submitted" [required]="true" [placeholder]="'Zip'">
                            </app-input>
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-4 d-flex align-items-center justify-content-end">
                            <label class="text-end">Email</label>
                        </div>
                        <div class="col-8">
                            <app-input [name]="'email'" [type]="'text'" [control]="demographic.controls['email']"
                                [submitted]="submitted" [required]="true">
                            </app-input>
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-4 d-flex align-items-center justify-content-end">
                            <label class="text-end">Phone</label>
                        </div>
                        <div class="col-8">
                            <app-input [name]="'phone'" [type]="'text'" [control]="demographic.controls['phone']"
                                [mask]="phoneMask" [submitted]="submitted" [required]="true">
                            </app-input>
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-4 d-flex align-items-center justify-content-end">
                            <label class="text-end">Organization</label>
                        </div>
                        <div class="col-8">
                            <app-select [form]="userForm" [control]="userForm.controls['organizationId']"
                                [controlName]="'organizationId'" [filter]="false" [submitted]="submitted" [data]="organizationsList"
                                [disabled]="role === 'ADVOCATE'" [name]="'displayName'" [value]="'id'"
                                (emitSelectedValue)="receiveSelectedValue($event)" class="mb-2">
                            </app-select>
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-4 d-flex align-items-center justify-content-end">
                            <label class="text-end">Advocate</label>
                        </div>
                        <div class="col-8">
                            <app-select [form]="userForm" [control]="userForm.controls['advocateId']" [controlName]="'advocateId'"
                                [filter]="false" [submitted]="submitted" [data]="advocateList" 
                                [disabled]="role === 'ADVOCATE'" [name]="'displayName'" [value]="'id'"
                                (emitSelectedValue)="receiveSelectedValue($event)" class="mb-2">
                            </app-select>
                        </div>
                    </div>
                </div>
            </div>
            <div id="safetyAndSecurityInformation" class="mb-3">
                <p class="fw-bold">Safety & Security Information</p>
                <div formGroupName="info">
                    <div class="row mb-1">
                        <div class="col-4 d-flex align-items-center justify-content-end">
                            <label class="text-end">Safe Word</label>
                        </div>
                        <div class="col-8">
                            <app-input [name]="'safeWord'" [type]="'text'" [control]="userForm.controls['safeWord']"
                                [submitted]="submitted" [required]="true">
                            </app-input>
                        </div>
                    </div>
                    <div class="row mb-1" *ngIf="editEnduser">
                        <div class="col-4 d-flex align-items-center justify-content-end">
                            <label class="text-end">Account Number</label>
                        </div>
                        <div class="col-8">
                            <app-input [name]="'accountNumber'" [type]="'text'" [control]="userForm.controls['accountNumber']"
                                [submitted]="submitted" [required]="false">
                            </app-input>
                        </div>
                    </div>
                    <div class="row mb-1" *ngIf="editEnduser">
                        <div class="col-4 d-flex align-items-center justify-content-end">
                            <label class="text-end">Device Phone</label>
                        </div>
                        <div class="col-8">
                            <app-input [name]="'phone'" [type]="'text'" [control]="userForm.controls['devicePhone']"
                                [mask]="phoneMask" [submitted]="submitted" [readonly]="true">
                            </app-input>
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-4 d-flex align-items-center justify-content-end">
                            <label class="text-end">Name Of Perpetrators</label>
                        </div>
                        <div class="col-8">
                            <app-input [name]="'nameOfPerpetrators'" [type]="'text'" [control]="info.controls['nameOfPerpetrators']" [submitted]="submitted" [required]="true">
                            </app-input>
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-4 d-flex align-items-center justify-content-end">
                            <label class="text-end">Perpetrators DOB</label>
                        </div>
                        <div class="col-8">
                            <mdb-form-control>
                                <input mdbInput [mdbDatepicker]="perpetratorDob" type="text" class="form-control" id="perpetratorDob"
                                    formControlName="perpetratorDob" [required]="true"/>
                                <mdb-datepicker-toggle [mdbDatepicker]="perpetratorDob"></mdb-datepicker-toggle>
                                <mdb-datepicker [inline]="true" [format]="dateFormat" [maxDate]="maxDate" #perpetratorDob></mdb-datepicker>
                            </mdb-form-control>
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-4 d-flex align-items-center justify-content-end">
                            <label class="text-end">Color, make and model of perpetrator’s car</label>
                        </div>
                        <div class="col-8">
                            <app-input [name]="'modelOfperpetrator'" [type]="'text'" [control]="info.controls['modelOfperpetrator']"
                                [submitted]="submitted" [required]="true">
                            </app-input>
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-4 d-flex align-items-center justify-content-end">
                            <label class="text-end">Perpetrator’s license plate</label>
                        </div>
                        <div class="col-8">
                            <app-input [name]="'perpetratorLicensePlate'" [type]="'text'" [control]="info.controls['perpetratorLicensePlate']"
                                [submitted]="submitted" [required]="true">
                            </app-input>
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-4 d-flex align-items-center justify-content-end">
                            <label class="text-end">Perpetrator owns or has access to a firearm</label>
                        </div>
                        <div class="col-8 pt-1">
                            <input type="radio" id="perpetratoryes" name="perpetratorOwns" value="yes" formControlName="perpetratorOwns" (change)="changeRadioData('perpetratorown',$event)"/> 
                            <label class="form-check-label" for="followupAll">Yes</label>
                            <input type="radio" id="perpetratorno" name="perpetratorOwns" value="no" formControlName="perpetratorOwns" (change)="changeRadioData('perpetratorown',$event)"/> 
                            <label class="form-check-label" for="followupAll">No</label>
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-4 d-flex align-items-center justify-content-end">
                            <label class="text-end">Is there an active restraining order or No Contact Order?</label>
                        </div>
                        <div class="col-8 pt-1">
                            <input type="radio" id="restrainingyes" name="activeRestrainingOrder" required="true" value="yes" formControlName="activeRestrainingOrder" (change)="changeRadioData('restraining',$event)"/> 
                            <label class="form-check-label" for="followupAll">Yes</label>
                            <input type="radio" id="restrainingno" name="activeRestrainingOrder" required="true" value="no" formControlName="activeRestrainingOrder" (change)="changeRadioData('restraining',$event)"/> 
                            <label class="form-check-label" for="followupAll">No</label>
                        </div>
                    </div>
                </div>
            </div>
            <div id="householdInformation" class="mb-3">
                <p class="fw-bold">Household Information</p>
                <div formGroupName="info">
                    <div class="row mb-1">
                        <div class="col-4 d-flex align-items-center justify-content-end">
                            <label class="text-end">Household Members</label>
                        </div>
                        <div class="col-4 d-flex align-items-center">
                            <div class="bg-stepper d-flex align-items-center">
                                <div class="col-3 text-center">
                                    <label>Adult</label>
                                </div>
                                <div class="col-9 stepper-rt-border">
                                    <app-input [name]="'adult'" [type]="'number'" step="1" min="0" max="10" [control]="householdMember.controls['adult']"
                                        [submitted]="submitted" [required]="true" (input)="onValueChange($event,'adult')">
                                    </app-input>
                                </div>
                            </div>
                        </div>
                        <div class="col-4 d-flex align-items-center">
                            <div class="bg-stepper d-flex align-items-center">
                                <div class="col-3 text-center">
                                    <label>Child</label>
                                </div>
                                <div class="col-9 stepper-rt-border">
                                    <app-input [name]="'children'" [type]="'number'" step="1" min="0" max="10" [control]="householdMember.controls['children']"
                                        [submitted]="submitted" [required]="true" (input)="onValueChange($event,'children')">
                                    </app-input>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-4 d-flex align-items-center justify-content-end">
                            <label class="text-end">Type of Residence</label>
                        </div>
                        <div class="col-8">
                            <app-select [form]="userForm" [control]="info.controls['typeOfResidence']"
                                [controlName]="'typeOfResidence'" [filter]="false" [submitted]="submitted" [data]="typeOfResidences"
                                [name]="'name'" [value]="'value'" [required]="true"
                                (emitSelectedValue)="receiveSelectedValue($event)" class="mb-2">
                            </app-select>
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-4 d-flex align-items-center justify-content-end">
                            <label class="text-end">Entrance Locations</label>
                        </div>
                        <div class="col-8">
                            <app-select [form]="userForm" [control]="info.controls['entranceLocation']"
                                [controlName]="'entranceLocation'" [filter]="false" [submitted]="submitted" [data]="entranceLocations"
                                [name]="'name'" [value]="'value'" [required]="true" [multiple]="true"
                                (emitSelectedValue)="receiveSelectedValue($event)" class="mb-2">
                            </app-select>
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-4 d-flex align-items-center justify-content-end">
                            <label class="text-end">Basement/Attic</label>
                        </div>
                        <div class="col-8">
                            <app-select [form]="userForm" [control]="info.controls['basementAttic']"
                                [controlName]="'basementAttic'" [filter]="false" [submitted]="submitted" [data]="homeTypes"
                                [name]="'name'" [value]="'value'" [required]="true"
                                (emitSelectedValue)="receiveSelectedValue($event)" class="mb-2">
                            </app-select>
                        </div>
                    </div>
                    <!--<div class="row mb-1">
                        <div class="col-4 d-flex align-items-center justify-content-end">
                            <label class="text-end">Subdivision</label>
                        </div>
                        <div class="col-8">
                            <app-input [name]="'subdivision'" [type]="'text'" [control]="userForm.controls['subdivision']"
                                [submitted]="submitted" [required]="false">
                            </app-input>
                        </div>
                    </div>-->
                    <div class="row mb-1">
                        <div class="col-4 d-flex align-items-center justify-content-end">
                            <label class="text-end">Firearms at home?</label>
                        </div>
                        <div class="col-8 pt-1">
                            <input type="radio" id="firearmyes" name="firearmAtHome" value="yes" formControlName="firearmAtHome" (change)="changeRadioData('firearm',$event)"/> 
                            <label class="form-check-label" for="followupAll">Yes</label>
                            <input type="radio" id="firearmno" name="firearmAtHome" value="no" formControlName="firearmAtHome" (change)="changeRadioData('firearm',$event)"/> 
                            <label class="form-check-label" for="followupAll">No</label>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="row mb-1">
                        <div class="col-4 d-flex align-items-center justify-content-end">
                            <label class="text-end">Any Dogs?</label>
                        </div>
                        <div class="col-8 pt-1">
                            <input type="radio" id="dogsyes" name="anyDog" value="yes" formControlName="anyDog" (change)="changeRadioData('dogs',$event)"/> 
                            <label class="form-check-label" for="followupAll">Yes</label>
                            <input type="radio" id="dogsno" name="anyDog" value="no" formControlName="anyDog" (change)="changeRadioData('dogs',$event)"/> 
                            <label class="form-check-label" for="followupAll">No</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-between">
                <button type="button" (click)="sendData()" class="btn btn-gray-light">
                    Cancel
                </button>
                <button type="button" class="btn btn-primary" (click)="save()" [disabled]="userForm.invalid || loading">
                    Save
                </button>
            </div>
        </form>
    </div>
</div>
