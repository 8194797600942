<div class="modal-header border-0">
    <h6 class="modal-title" id="exampleModalLabel">{{ title }}</h6>
    <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body scrollbar-primary thin">
    <form [formGroup]="configureColumnForm" autocomplete="off">
        <div class="md-form mb-2" *ngFor="let key of configureColumnForm.controls | keyvalue">
            <label>
                <input type="checkbox" [formControlName]="key.key" />
                {{ key.key | titlecase }}
            </label>
        </div>
    </form>
</div>
<div class="modal-footer justify-content-between pt-0">
    <button type="button" class="btn btn-gray-light" (click)="modalRef.close()">{{cancelButtonLabel}}</button>
    <button type="button" class="btn btn-primary d-flex align-items-center" (click)="save()">Save</button>
</div>
