<div class="modal-header shadow-sm">
    <h6 class="modal-title" id="exampleModalLabel">
        {{
        title + ' Administrator'
        }}
    </h6>
    <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body scrollbar-primary thin">
    <form [formGroup]="administratorForm" autocomplete="off">
        <div formGroupName="demographic">
            <div class="row gx-3 mb-2">
                <div class="col-4">
                    <label for="firstName" class="form-label">First Name</label>
                    <app-input [name]="'firstName'" [type]="'text'" [control]="demographic.controls['firstName']"
                        [submitted]="submitted" [required]="true">
                    </app-input>
                </div>
                <div class="col-4">
                    <label for="middleName" class="form-label">Middle Name</label>
                    <app-input [name]="'middleName'" [type]="'text'" [control]="demographic.controls['middleName']"
                        [submitted]="submitted">
                    </app-input>
                </div>
                <div class="col-4">
                    <label for="lastName" class="form-label">Last Name</label>
                    <app-input [name]="'lastName'" [type]="'text'" [control]="demographic.controls['lastName']"
                        [submitted]="submitted">
                    </app-input>
                </div>
            </div>
            <div class="md-form mb-2">
                <label for="email" class="form-label">Email</label>
                <app-input [name]="'email'" [type]="'text'" [control]="demographic.controls['email']"
                    [submitted]="submitted" [required]="true" [readonly]="formData">
                </app-input>
            </div>
            <div class="md-form mb-2">
                <label for="phone" class="form-label">Phone Number</label>
                <app-input [name]="'phone'" [type]="'text'" [control]="demographic.controls['phone']" [mask]="phoneMask"
                    [submitted]="submitted" [required]="true">
                </app-input>
            </div>
        </div>
        <div formGroupName="organizations" *ngIf="!formData?.superRoleId">
            <div class="md-form">
                <label for="organizationId" class="form-label">Organization</label>
                <app-select [form]="organizations" [control]="organizations.controls['organizationId']"
                    [controlName]="'organizationId'" [filter]="false" [disabled]="disableOrg" [submitted]="submitted"
                    [data]="organizationsList" [name]="'displayName'" [value]="'id'"
                    (emitSelectedValue)="receiveSelectedValue($event)" class="me-2"></app-select>
            </div>
            <div class="md-form">
                <label for="role" class="form-label">Role</label>
                <app-select [form]="organizations" [control]="organizations.controls['roleId']" [controlName]="'roleId'"
                    [filter]="false" [disabled]="disableRole" [submitted]="submitted" [data]="roles" [name]="'name'"
                    [value]="'id'" (emitSelectedValue)="receiveSelectedValue($event)" class="me-2"></app-select>
            </div>
        </div>
        <div class="row" *ngIf="isAdvocateSelected">
            <div class="col-4">
                <div formGroupName="emergencyNotifications">
                    <div class="md-form">
                        <label for="recieveNotifications" class="form-label" style="height: 40px">Get Emergency Alert Notifications</label>
                        <div class="row">
                            <div class="col-8">
                                <small>Notify via Email</small>
                            </div>
                            <div class="col-4 d-flex justify-content-end">
                                <div class="form-check form-switch">
                                    <input
                                        mdbCheckbox
                                        class="form-check-input"
                                        type="checkbox"
                                        data-cy="notifyViaEmail"
                                        formControlName="email"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-8">
                                <small>Notify via SMS</small>
                            </div>
                            <div class="col-4 d-flex justify-content-end">
                                <div class="form-check form-switch">
                                    <input
                                        mdbCheckbox
                                        class="form-check-input"
                                        type="checkbox"
                                        data-cy="notifyViaSMS"
                                        formControlName="sms"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div formGroupName="warningNotifications">
                    <div class="md-form">
                        <label for="recieveNotifications" class="form-label" style="height: 40px">Get Warning Notifications</label>
                        <div class="row">
                            <div class="col-8">
                                <small>Notify via Email</small>
                            </div>
                            <div class="col-4 d-flex justify-content-end">
                                <div class="form-check form-switch">
                                    <input
                                        mdbCheckbox
                                        class="form-check-input"
                                        type="checkbox"
                                        data-cy="notifyViaEmail"
                                        formControlName="email"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-8">
                                <small>Notify via SMS</small>
                            </div>
                            <div class="col-4 d-flex justify-content-end">
                                <div class="form-check form-switch">
                                    <input
                                        mdbCheckbox
                                        class="form-check-input"
                                        type="checkbox"
                                        data-cy="notifyViaSMS"
                                        formControlName="sms"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div formGroupName="followUpNotifications">
                    <div class="md-form">
                        <label for="recieveNotifications" class="form-label" style="height: 40px">Get Follow-up Notifications</label>
                        <div class="row">
                            <div class="col-8">
                                <small>Notify via Email</small>
                            </div>
                            <div class="col-4 d-flex justify-content-end">
                                <div class="form-check form-switch">
                                    <input
                                        mdbCheckbox
                                        class="form-check-input"
                                        type="checkbox"
                                        data-cy="notifyViaEmail"
                                        formControlName="email"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-8">
                                <small>Notify via SMS</small>
                            </div>
                            <div class="col-4 d-flex justify-content-end">
                                <div class="form-check form-switch">
                                    <input
                                        mdbCheckbox
                                        class="form-check-input"
                                        type="checkbox"
                                        data-cy="notifyViaSMS"
                                        formControlName="sms"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<div class="modal-footer justify-content-between pt-0">
    <button type="button" class="btn btn-gray-light" (click)="modalRef.close()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="save()" [disabled]="administratorForm.invalid || loading">
        <span class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true" *ngIf="submitted"></span>
        {{formData ? 'Update': 'Save'}}</button>
</div>