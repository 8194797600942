import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { MESSAGE_CONSTANTS } from 'src/app/constants';
import { ToasterService } from 'src/app/services';

@Component({
    selector: 'app-configure-column-modal',
    templateUrl: './configure-column-modal.component.html',
    styleUrls: ['./configure-column-modal.component.scss']
})
export class ConfigureColumnModalComponent implements OnInit {
    title!: string;
    body!: string;
    cancelButtonLabel: string = 'Cancel';
    configureColumnForm!: FormGroup;
    formData: any = {};

    constructor(
        private _toasterService: ToasterService,
        private _formBuilder: FormBuilder,
        public modalRef: MdbModalRef<ConfigureColumnModalComponent>,
    ) {
        
     }

    ngOnInit(): void {
        this.setConfigureColumnForm();
    }

    setConfigureColumnForm() {
        this.configureColumnForm = this._formBuilder.group(
            Object.keys(this.formData).reduce((acc:any, key:any) => {
                acc[key] = [this.formData[key]]; // Initial value from userColumnData
                return acc;
            }, {})
        );    
    }

    save() {
        this.modalRef.close(this.configureColumnForm.value);
        this._toasterService.show(
            'Success',
            MESSAGE_CONSTANTS.USER_DETAIL_COLUMN_SUCCESS
        );
    }

    close() {
        this.modalRef.close();
    }
}