import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { MESSAGE_CONSTANTS, USER_COLUMN_ORDER, USER_COLUMN_NAME } from 'src/app/constants';
import { ToasterService } from 'src/app/services';

@Component({
    selector: 'app-configure-column-modal',
    templateUrl: './configure-column-modal.component.html',
    styleUrls: ['./configure-column-modal.component.scss']
})
export class ConfigureColumnModalComponent implements OnInit {
    title!: string;
    body!: string;
    cancelButtonLabel: string = 'Cancel';
    configureColumnForm!: FormGroup;
    formData: any = {};
    keys: any;
    constructor(
        private _toasterService: ToasterService,
        private _formBuilder: FormBuilder,
        public modalRef: MdbModalRef<ConfigureColumnModalComponent>,
    ) {
        
     }

    ngOnInit(): void {
        this.setConfigureColumnForm();
    }

    setConfigureColumnForm() {
        const sortedKeys = Object.keys(USER_COLUMN_ORDER) .sort((a, b) => (USER_COLUMN_ORDER as any)[a] - (USER_COLUMN_ORDER as any)[b]);
        // Build the form group using sorted keys, adding them in order to an array
        const formGroupData = sortedKeys.reduce((acc: any, key: string) => {
            if (this.formData.hasOwnProperty(key)) {
                acc.push({ key, value: this.formData[key] }); // Use push to store in array
            }
            return acc;
        }, []);
        const result = formGroupData.reduce((acc: any, control: any) => {
            acc.keys.push(control.key); // Maintain order in an array
            acc.data[control.key] = control.value; // Store key-value pairs in an object
            return acc;
        }, { keys: [], data: {} });
        this.configureColumnForm = this._formBuilder.group(result.data);
        this.keys = result.keys;
    }

    getNameByValue(value: string): string {
        const matchedItem = USER_COLUMN_NAME.find((item:any) => item.value === value);
        return matchedItem ? matchedItem.name : value;
    }

    save() {
        this.modalRef.close(this.configureColumnForm.value);
        this._toasterService.show(
            'Success',
            MESSAGE_CONSTANTS.USER_DETAIL_COLUMN_SUCCESS
        );
    }

    close() {
        this.modalRef.close();
    }
}