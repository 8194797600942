<div class="modal-header shadow-sm">
    <h6 class="modal-title" id="exampleModalLabel" *ngIf="!emailDisplay">
        {{ title }}
    </h6>
    <img *ngIf="emailDisplay" src="../../../../assets/img/png/NexionSolutionslogo-Login.png" width="80" class="logo-full">
    <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body scrollbar-primary thin">
    <form [formGroup]="audioForm" autocomplete="off" *ngIf="!emailDisplay">
        <div class="row gx-3 mb-2">
            <div class="col-6">
                <label for="startDate" class="audio-label">Date From</label>
                <mdb-form-control>
                    <input mdbInput [mdbDatepicker]="startDate" type="text" class="form-control" id="startDate"
                        formControlName="startDate" />
                    <mdb-datepicker-toggle [mdbDatepicker]="startDate"></mdb-datepicker-toggle>
                    <mdb-datepicker [inline]="true" [format]="dateFormat" [maxDate]="maxDate" #startDate></mdb-datepicker>
                </mdb-form-control>
            </div>
            <div class="col-6">
                <label for="endDate" class="audio-label">Date To</label>
                <mdb-form-control>
                    <input mdbInput [mdbDatepicker]="endDate" type="text" class="form-control" id="endDate"
                        formControlName="endDate" />
                    <mdb-datepicker-toggle [mdbDatepicker]="endDate"></mdb-datepicker-toggle>
                    <mdb-datepicker [inline]="true" [format]="dateFormat" [maxDate]="maxDate" #endDate></mdb-datepicker>
                </mdb-form-control>
            </div>
        </div>
        <div class="md-form mb-2">
            <label for="reason" class="audio-label">Reason for Request</label>
            <textarea rows="5" placeholder="" formControlName="reasonForRequest"></textarea>
        </div>
    </form>
    <div *ngIf="emailDisplay">
        <p><b>Subject:</b> Audio Recording Request for {{ formData.enduserName }}</p>
        <p><b>Dear Night Watch Admin,</b><br>  
            An audio recording request has been submitted via NightWeb. Below are the details:
        </p>
        <ul>
            <li><b>Submitted By:</b> {{ formData.advocateName }}</li>
            <li><b>Submission Date:</b> {{ currDate }}</li>
            <li><b>End-User Account:</b> {{ formData.enduserName }} / {{ formData.accountNumber }}</li>
            <li><b>Requested Dates:</b> {{ fromDate }} to {{ toDate }}</li>
            <li *ngIf="reason"><b>Reason:</b> {{ reason }}</li>
        </ul>   
        <p><b>Next Steps:</b></p>   
        <p>Please coordinate with the RRMS team to retrieve the audio recording. Once received, kindly share it directly with the advocate via their contact details: {{ formData.advocateEmail }} / {{ formData.advocatePhone }}.<br/> 
        Thank you,</p> 
    </div>
</div>
<div class="modal-footer justify-content-between pt-0">
    <button type="button" class="btn btn-gray-light" (click)="modalRef.close()">Cancel</button>
    <button type="button" *ngIf="!emailDisplay" class="btn btn-primary" (click)="save()" [disabled]="audioForm.invalid || loading">Submit</button>
    <button type="button" *ngIf="emailDisplay" class="btn btn-primary" (click)="sendEmail()">Send</button>
</div>