export const ENDUSER = {
    typeOfResidenceLists: [
        { name: 'Duplex', value: 'duplex' },
        { name: 'Single Family', value: 'single family' },
        { name: 'Apartment', value: 'apartment' },
        { name: 'Condo', value: 'condo' }
    ],
    entranceLocationLists: [
        { name: 'Front', value: 'front' },
        { name: 'Rear', value: 'rear' },
        { name: 'Side', value: 'side' }
    ],
    homeTypeLists: [
        { name: 'Basement', value: 'basement' },
        { name: 'Attic', value: 'attic' },
        { name: 'Both', value: 'both' }
    ]
};