<div *ngIf="!isAddEditEndUser; else addEditEndUser">
    <form [formGroup]="endUserForm" autocomplete="off">
        <div class="d-flex justify-content-between">
            <div class="d-flex justify-content-start">
                <app-search (emitSearchKeyword)="receiveSearchKeyword($event)"></app-search>
                <button type="button" class="btn btn-light bg-white border ms-2" (click)="advFilterCollapse.toggle()"
                    [attr.aria-expanded]="!advFilterCollapse.collapsed" aria-controls="advanceFilterCollapse">
                    <i-bs [name]="advFilterCollapse.collapsed ? 'filter': 'x-lg'"></i-bs> Filter
                </button>
            </div>
            <div class="d-flex justify-content-end align-items-center">
                <app-select [form]="endUserForm" [control]="endUserForm.controls['advocateId']" [controlName]="'advocateId'" [clearButton]="true"
                    [filter]="true" [submitted]="submitted" [data]="advocates" [name]="'label'" [value]="'value'" [disabled]="!advocates.length"
                    [placeholder]="advocates.length ? 'Select Advocate' : 'Loading...'" [width]="'190px'" (emitSelectedValue)="receiveSelectedValue($event)" class="me-2 select-outline"></app-select>
                <app-select [form]="endUserForm" [control]="endUserForm.controls['limit']" [controlName]="'limit'"
                    [filter]="false" [submitted]="submitted" [data]="limits" [name]="'label'" [value]="'value'" [width]="defaultLimitSelectWidth"
                    (emitSelectedValue)="receiveSelectedValue($event)" class="me-2 select-outline"></app-select>
                <button type="button" *ngIf="isWriteAccessible" class="btn btn-primary" (click)="onAddEditUser('')">
                    <i-bs name="plus"></i-bs> Add
                </button>
            </div>
        </div>

        <div class="row" id="advanceFilterCollapse" mdbCollapse #advFilterCollapse="mdbCollapse">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row g-3 mb-2">
                            <div class="col-3">
                                <label for="firstName" class="form-label">First Name</label>
                                <app-input [name]="'firstName'" [type]="'text'"
                                    [control]="endUserForm.controls['firstName']" [submitted]="submitted">
                                </app-input>
                            </div>
                            <div class="col-3">
                                <label for="lastName" class="form-label">Last Name</label>
                                <app-input [name]="'lastName'" [type]="'text'" [control]="endUserForm.controls['lastName']"
                                    [submitted]="submitted">
                                </app-input>
                            </div>
                            <div class="col-3">
                                <label for="email" class="form-label">Email</label>
                                <app-input [name]="'email'" [type]="'text'" [control]="endUserForm.controls['email']"
                                    [submitted]="submitted">
                                </app-input>
                            </div>
                            <div class="col-3">
                                <label for="phone" class="form-label">Associated Phone</label>
                                <app-input [name]="'phone'" [type]="'text'" [control]="endUserForm.controls['phone']"
                                    [mask]="phoneMask" [submitted]="submitted">
                                </app-input>
                            </div>
                        </div>
                        <div class="row g-3">
                            <div class="col-3">
                                <label for="status" class="form-label">Status</label>
                                <app-select [form]="endUserForm" [control]="endUserForm.controls['status']"
                                    [controlName]="'endUserForm'" [submitted]="submitted" [data]="userStatusList"
                                    [name]="'name'" [value]="'value'" [clearButton]="true"
                                    (emitSelectedValue)="receiveSelectedValue($event)"
                                    [placeholder]="'Select device status'"></app-select>
                            </div>
                            <div class="col-3">
                                <label for="createdFrom" class="form-label">Created From</label>
                                <mdb-form-control>
                                    <div class="datepicker-wrapper">
                                        <input mdbInput [readonly]="true" [mdbDatepicker]="createdFrom" type="text" class="form-control"
                                            id="createdFrom" formControlName="createdFrom" />
                                        <button *ngIf="endUserForm.value.createdFrom" class="datepicker-clear-button" (click)="clearDatePicked('createdFrom')">
                                            <i class="fas fa-times"></i>
                                        </button>
                                        <mdb-datepicker-toggle [mdbDatepicker]="createdFrom"></mdb-datepicker-toggle>
                                        <mdb-datepicker [inline]="true" [format]="dateFormat" [maxDate]="getMaxDate()" (dateChanged)="getFiltered()" #createdFrom></mdb-datepicker>
                                    </div>
                                </mdb-form-control>
                            </div>
                            <div class="col-3">
                                <label for="createdTo" class="form-label">Created To</label>
                                <mdb-form-control>
                                    <div class="datepicker-wrapper">
                                        <input mdbInput [readonly]="true" [mdbDatepicker]="createdTo" type="text" class="form-control"
                                            id="createdTo" formControlName="createdTo" />
                                        <button *ngIf="endUserForm.value.createdTo" class="datepicker-clear-button" (click)="clearDatePicked('createdTo')">
                                            <i class="fas fa-times"></i>
                                        </button>
                                        <mdb-datepicker-toggle [mdbDatepicker]="createdTo"></mdb-datepicker-toggle>
                                        <mdb-datepicker [inline]="true" [format]="dateFormat" [minDate]="this.endUserForm.controls['createdFrom'].value && getMinDate()"
                                        [maxDate]="getMaxDate()" (dateChanged)="getFiltered()" #createdTo></mdb-datepicker>
                                    </div>
                                </mdb-form-control>
                            </div>
                            <div class="col-3 d-flex align-items-end">
                                <button type="button" class="btn btn-light border flex-grow-1 me-1"
                                    (click)="clear()">Clear</button>
                                <button type="button" class="btn btn-primary flex-grow-1  ms-1"
                                    (click)="search()">Search</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <div 
        #tableElement 
        class="table-responsive mt-3 scrollbar-primary thin overflow-auto" 
        (scroll)="!loading && onScroll($event)" 
        >
        <table class="table table-striped">
            <thead class="bg-gray-light text-gray-dark position-sticky top-0" style="z-index: 999;">
                <tr>
                    <th scope="col">State</th>
                    <th scope="col">Name</th>
                    <th scope="col">Contact</th>
                    <th scope="col">Address</th>
                    <th scope="col">Advocate</th>
                    <th scope="col">Linked Device</th>
                    <th scope="col">Status</th>
                    <th scope="col">Updated</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let user of endUsers; let i = index">
                    <tr>
                        <td><i class="fas fa-circle" [style.color]="user.currentState"></i></td>
                        <td [routerLink]="['/end-users', user.id]"
                            class="cursor-pointer">
                            <div class="d-flex flex-column align-items-start">
                                <span>{{
                                    (user?.demographic?.firstName?? '')
                                    + ' ' +
                                    (user?.demographic?.middleName?? '')
                                    + ' ' +
                                    (user?.demographic?.lastName??'')
                                    }}
                                </span>
                            </div>
                        </td>
                        <td [routerLink]="['/end-users', user.id]"
                            class="cursor-pointer">
                            <div class="d-flex flex-column">
                                <span class="text-truncate text-wrapper" [mdbTooltip]="user?.demographic?.email">
                                    {{user?.demographic?.email | trim: 15 :useTrim}}
                                </span>
                                <span class="text-truncate text-wrapper">{{user?.demographic?.phone | formatPhoneNumber}}</span>
                            </div>
                        </td>
                        <td [routerLink]="['/end-users', user.id]" 
                            class="cursor-pointer">
                            <div class="d-flex flex-column">
                                <span>{{user?.demographic?.address?.home?.addressLine1}}
                                    {{user?.demographic?.address?.home?.addressLine2}}</span>
                                <span>{{
                                    user?.demographic?.address?.home?.city + ', ' +
                                    user?.demographic?.address?.home?.state +
                                    ' ' +
                                    user?.demographic?.address?.home?.zip}}
                                </span>
                            </div>
                        </td>
                        <td [routerLink]="['/end-users', user.id]"
                            class="cursor-pointer">
                            <span>{{ user?.advocate?.name?? '' }} </span><br />
                            <span class="badge badge-info fs-1">{{user?.advocate?.organizationName}}</span>
                        </td>
                        <td [routerLink]="['/end-users', user.id]"
                            class="cursor-pointer">
                            <div class="d-flex flex-column">
                                <span class="text-truncate text-wrapper">{{user?.activeDevice?.info?.imeiNumber || user?.activeDevice?.info?.eidNumber}}</span>
                                <span class="text-truncate text-wrapper">{{user?.activeDevice?.info?.associatedPhone | formatPhoneNumber}}</span>
                            </div>
                        </td>
                        <td [routerLink]="['/end-users', user.id]"
                            class="cursor-pointer">
                            <span class="badge rounded-pill bg-opacity-25" [ngClass]="{
                                'bg-primary text-primary': user?.status?.toLowerCase() === userStatus.NEW,
                                'bg-success-light text-success': user?.status?.toLowerCase() === userStatus.ACTIVATED,
                                'bg-warning text-warning': user?.status?.toLowerCase() === userStatus.IN_PROGRESS
                                }">
                                {{ user.status }}
                            </span>
                        </td>
                        <td [routerLink]="['/end-users', user.id]"
                            class="cursor-pointer">
                            <div class="d-flex flex-column">
                                <span>{{user?.updatedAt | date: defaultDateFormat}}</span>
                                <span>{{user?.updatedBy?.name}}</span>
                            </div>
                        </td>
                        <td>
                            <div mdbDropdown class="dropdown">
                                <a role="button" id="dropdownMenuButton" aria-expanded="false" mdbDropdownToggle>
                                    <i-bs name="three-dots-vertical"></i-bs>
                                </a>
                                <ul mdbDropdownMenu class="dropdown-menu dropdown-menu-end"
                                    aria-labelledby="dropdownMenuButton">
                                    <li *ngIf="isWriteAccessible"><a class="dropdown-item" role="button"
                                            (click)="onAddEditUser(user)">Edit</a></li>
                                    <li *ngIf="!user?.activeDevice"><a class="dropdown-item" role="button"
                                            (click)="openLinkDeviceModal(user)">Link
                                            Device</a></li>
                                    <li *ngIf="user?.activeDevice && Object.keys(user.activeDevice).length > 0"><a
                                            class="dropdown-item" role="button"
                                            (click)="openUnlinkDeviceModal(user)">Unlink
                                            Device</a></li>
                                    <li *ngIf="user?.activeDevice && Object.keys(user.activeDevice).length > 0"><a
                                            class="dropdown-item" role="button"
                                            (click)="openEditConfigurationModal(user)">Edit Configuration</a></li>
                                    <li><a class="dropdown-item" role="button"
                                            (click)="deleteUser($event, user)">Delete</a></li>
                                </ul>
                            </div>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>
<div class="pt-3 pb-4 mb-4 text-center" *ngIf="!loading && endUsers.length == 0">
    <i-bs name="journal-text" width="2rem" height="2rem"></i-bs>
    <p class="mt-2">No user data.</p>
</div>

<div class="mt-3" *ngIf="loading && endUsers.length == 0">
    <app-loader [loader]="loading"></app-loader>
</div>

<ng-template #addEditEndUser>
    <app-add-edit-enduser-scrollable-form [formData]="userInfo" (dataEmitter)="receiveData($event)"></app-add-edit-enduser-scrollable-form>
</ng-template>
