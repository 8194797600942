export const MESSAGE_CONSTANTS = {
    UNAUTHORIZED: "User unauthorized. Please login to continue.",
    SESSION_EXPIRED: "Session expired. Please re-login.",
    FORBIDDEN: "Forbidden.",
    SERVER_ERROR: "Server error. Please contact admin.",
    TIMEOUT: "Request timed out. Please contact admin.",
    BAD_REQUEST: "Invalid payload",
    SERVICE_UNAVAILABLE: 'Service unavailable. Please try again later.',

    // DEVICE
    DEVICE_UPDATED: "Device info updated successfully.",
    DEVICE_ADDED: "Device added successfully.",
    DEVICE_REMOVED: "Device removed successfully.",
    DEVICE_UNLINKED: "Device unlinked successfully.",
    DEVICE_UPLOAD_PROCESSED: 'Your device upload has been processed. Please check your email for the report containing the successful and failed uploads of devices.',

    // USER
    USER_UPDATED: "User info updated successfully.",
    USER_ADDED: "User added successfully.",
    USER_REMOVED: "End user removed successfully.",
    REMOVE_EMERGENCY_CADENCE_SUCCESS: "Removed emergency cadence successfully.",
    ASSOCIATED_PHONE_SYNC_RAPID_SUCCESS: "Associated phone synced in rapid successfully.",
    ACCOUNT_NUMBER_SYNC_RAPID_SUCCESS: "Account number synced in rapid successfully.",
    CHECK_GPS_SUCCESS: "GPS checked successfully.",
    CHECK_NETWORK_SUCCESS: "Network checked successfully.",

    // Irreversible
    DELETE_CONFIRMATION: "This action is irreversible. Are you sure you want to delete?",

    // OTP CODE
    NO_CODE: "OTP code was not entered. Please enter OTP code to verify.",
    VERIFIED: "OTP code verified.",

    // ORGANIZATION
    ORG_ADDED: "Organization added successfully.",
    ORG_UPDATED: "Organization updated successfully.",
    ORG_REMOVED: "Organization removed successfully.",

    // ADMIN
    ADMIN_ADDED: "Admin added successfully.",
    ADMIN_UPDATED: "Admin info updated successfully.",
    ADMIN_REMOVED: "Admin removed successfully.",
    ADMIN_STATUS_UPDATED: "Admin status updated successfully.",

    // AUTH
    FORGOT_PASSWORD: "Password reset link has been sent to the email provided.",
    CHANGED_PASSWORD: "Password changed. Re-login to continue.",
    RESET_PASSWORD: "Password has been set. Please Login.",

    //NOTES
    NOTE_ADDED: "Note added successfully.",
    NOTE_UPDATED: "Note updated successfully.",
    NOTE_DELETED: "Note removed successfully.",

    //NOTIFICATIONS
    FOLLOWUP_ADDED: "Follow-up added successfully.",
    FOLLOWUP_UPDATED: "Follow-up updated successfully.",
    FOLLOWUP_CLEARED: "Follow-up cleared successfully.",
    MARKED_ALL_READ: "All notifications have been successfully marked as read.",

    //MIGRATION
    MIGRATION_SUCCESS: "Migration successful.",
    MIGRATION_FAILED: "Migration failed",
    MIGRATION_DELAY: "The migration is in progress, expected to take five minutes, with an automatic rollback for any unsuccessful user migrations.",

    //DEVICES
    EMERGENCY_CLEAR_SUCCESS: 'Emergency cleared successfully.',

    //Configure Column
    USER_DETAIL_COLUMN_SUCCESS : "User Detail Column save successfully.",

    AUDIO_REQUESTED_SUCCESS : "Audio Requested Successfully.",
}