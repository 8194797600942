export const USER_DETAIL_COLUMN = [
    { headerName: 'Emergency', dataName: 'emergency', bodyElement: { value: 'isEmergencyState', dataType: 'boolean' }, enable: true},
	{ headerName: 'Packet Sequence', dataName: 'packetSequence', bodyElement: { value: 'packetSequence', dataType: 'float' }, enable: true},
	{ headerName: 'Watch Check-In', dataName: 'watchCheckIn', bodyElement: { value: 'checkInTime', dataType: 'created' }, enable: true},
	{ headerName: 'Received Check-In', dataName: 'receviedCheckIn', bodyElement: { value: 'createdAt', dataType: 'created' }, enable: true},
	{ headerName: 'Expected Check-In', dataName: 'expectedCheckIn', bodyElement: { value: 'expected', dataType: 'created' }, enable: true},
	{ headerName: 'Deviation', dataName: 'deviation', bodyElement: { value: 'deviation', dataType: 'string' }, enable: true},
	{ headerName: 'GPS', dataName: 'gps', bodyElement: { value: 'latLong' }, enable: true},
	{ headerName: 'Heart Rate', dataName: 'heartRate', bodyElement: { value: 'heartRate', concatString: ' bpm' }, enable: true},
	{ headerName: 'Signal Strength', dataName: 'signalStrength', bodyElement: { value: 'signalStrength', concatString: ' bar' }, enable: true},
	{ headerName: 'LTE Strength', dataName: 'lteStrength', bodyElement: { value: 'lteSignalStrength', dataType: 'lteStrength' }, enable: true},
	{ headerName: 'Battery Status', dataName: 'batteryStatus', bodyElement: { value: 'batteryStatus', dataType: 'percent' }, enable: true},
	{ headerName: 'Charging', dataName: 'charging', bodyElement: { value: 'isCharging', dataType: 'boolean' }, enable: true},
	{ headerName: 'On Wrist', dataName: 'onWrist', bodyElement: { value: 'isOnWrist', dataType: 'boolean' }, enable: false},
]

export const USER_COLUMN_ORDER = {
	'emergency': 1, 
	'packetSequence': 2, 
	'watchCheckIn': 3,
	'receviedCheckIn': 4,
	'expectedCheckIn': 5,
	'deviation': 6,
	'gps': 7,
	'heartRate': 8,
	'signalStrength': 9,
	'lteStrength': 10,
	'batteryStatus': 11,
	'charging': 12,
	'onWrist': 13
}

export const USER_COLUMN_NAME = [
	{name: "Emergency", value:"emergency"},
	{name: "Packet Sequence", value:"packetSequence"},
	{name: "Watch Check-In", value:"watchCheckIn"},
	{name: "Received Check-In", value:"receviedCheckIn"},
	{name: "Expected Check-In", value:"expectedCheckIn"},
	{name: "Deviation", value:"deviation"},
	{name: "GPS", value:"gps"},
	{name: "Heart Rate", value:"heartRate"},
	{name: "Signal Strength", value:"signalStrength"},
	{name: "LTE Strength", value:"lteStrength"},
	{name: "Battery Status", value:"batteryStatus"},
	{name: "Charging", value:"charging"},
	{name: "On Wrist", value:"onWrist"}
]