<div [formGroup]="form">
    <mdb-form-control>
        <mdb-select class="position-relative" [style.width]="width" [filter]="filter" [clearButton]="clearButton" [formControl]="control"
            [notFoundMsg]="notFoundMsg" [placeholder]="placeholder" [disabled]="disabled" [multiple]="multiple"
            (valueChange)="handleChange($event)" [class.is-invalid]="submitted && required && control?.errors"
            [required]="required">
            <mdb-option *ngFor="let option of data" [value]="getValue(option)" (click)="$event.stopPropagation()">
                {{
                getOption(option)
                }}
            </mdb-option>
        </mdb-select>
    </mdb-form-control>
</div>