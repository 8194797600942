import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { InputType } from 'src/app/enums';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs';
import { MESSAGE_CONSTANTS, NIGHTWEB_APP_CONSTANT, STATUS_CODES, US_STATES, ENDUSER } from 'src/app/constants';
import { AdminService, OrganizationService, ToasterService, UserService } from 'src/app/services';
import { DateUtility, NightwebUtil } from 'src/app/utils';

@Component({
  selector: 'app-add-edit-enduser-scrollable-form',
  templateUrl: './add-edit-enduser-scrollable-form.component.html',
  styleUrls: ['./add-edit-enduser-scrollable-form.component.css']
})
export class AddEditEnduserScrollableFormComponent implements OnInit {

  @Output() dataEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() formData!: any;

  inputType = InputType;
  selectedNavIndex: number = 0;

  enduserSideNav = [
    {label: "Basic Information",value: "basicInformation"},
    {label: "Safety & Security Information",value: "safetyAndSecurityInformation"},
    {label: "Household Information",value: "householdInformation"},
  ];

  userForm!: FormGroup;

  loading = false;
  submitted = false;
  organizationsList: any[] = [];
  advocateList: any[] = [];
  phoneMask = NIGHTWEB_APP_CONSTANT.phoneMask;
  status_codes = STATUS_CODES;
  states = US_STATES;
  typeOfResidences = ENDUSER.typeOfResidenceLists;
  entranceLocations = ENDUSER.entranceLocationLists;
  homeTypes = ENDUSER.homeTypeLists;
  dateFormat = NIGHTWEB_APP_CONSTANT.mdbDefaultDateFormat;
  maxDate: Date =  new Date();
  role: string = '';
  genderList: any[] = NIGHTWEB_APP_CONSTANT.genderList;
  editEnduser = false;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private dateUtility: DateUtility,
    private adminService: AdminService,
    private nightwebUtil: NightwebUtil,
    private toasterService: ToasterService,
    private organizationService: OrganizationService,
  ) {
    this.role = this.nightwebUtil.getRole();
    this.setUserForm();
   }

   get demographic(): FormGroup {
    return this.userForm.get('demographic') as FormGroup;
  }

  get address(): FormGroup {
    return this.userForm.controls['demographic']?.get('address') as FormGroup;
  }

  get home(): FormGroup {
    return this.address?.get('home') as FormGroup;
  }

  get info(): FormGroup {
    return this.userForm?.get('info') as FormGroup;
  }

  get householdMember(): FormGroup {
    return this.userForm.controls['info']?.get('householdMember') as FormGroup;
    //return this.userForm?.get('householdMember') as FormGroup;
  }

  ngOnInit(): void {
    const devicePhone = this.formData?.activeDevice?.info?.associatedPhone ? this.formData?.activeDevice?.info?.associatedPhone : '';
    if (navigator.userAgent.includes("Edg")) {
      document.documentElement.classList.add("edge-browser");
    }
    this.getAllOrganizations();
    if (Object.keys(this.formData).length > 0) {
      this.editEnduser = true;
      const { home, work } = this.formData?.demographic?.address;
      //const { householdMember } = this.formData?.info;
      this.userForm.patchValue({
        ...this.formData,
        devicePhone : devicePhone
      });
      if (this.formData?.organizationId) {
        this.getAdvocates(this.formData.organizationId);
      }
      this.userForm?.get('demographic')?.patchValue({
        address: home,
        dob: new Date(this.userForm.get('demographic')?.value?.dob)
      });

      this.userForm?.get('info')?.patchValue({
        householdMember: this.formData?.info?.householdMember ? this.formData?.info?.householdMember : '',
        perpetratorDob: this.formData?.info?.perpetratorDob ? new Date(this.userForm.get('info')?.value?.perpetratorDob) : '',
        entranceLocation: this.formData?.info?.entranceLocation ? this.nightwebUtil.convertToArray(this.formData?.info?.entranceLocation) : ''
      });
    }
  }

  setUserForm() {
    this.userForm = this.formBuilder.group({
      id: [],
      demographic: this.formBuilder.group({
        firstName: ['', Validators.required],
        middleName: [''],
        lastName: [''],
        dob: [''],
        gender: ['Male'],
        phone: ['', Validators.required],
        email: ['', [Validators.required, Validators.pattern(NIGHTWEB_APP_CONSTANT.emailPattern)]],
        address: this.formBuilder.group({
          home: this.formBuilder.group({
            addressLine1: ['', Validators.required],
            addressLine2: [''],
            city: ['', Validators.required],
            state: ['', Validators.required],
            zip: ['', [Validators.pattern(NIGHTWEB_APP_CONSTANT.zipPattern)]]
          })
        })
      }),
      status: ['New'],
      safeWord: ['', Validators.required],
      accountNumber: [''],
      relatedPersons: this.formBuilder.array([]),
      organizationId: ['', Validators.required],
      advocateId: [null],
      devicePhone: [''],
      info: this.formBuilder.group({
        nameOfPerpetrators: ['', Validators.required],
        perpetratorDob: [''],
        modelOfperpetrator: ['', Validators.required],
        perpetratorLicensePlate: ['', Validators.required],
        perpetratorOwns: ['', Validators.required],
        activeRestrainingOrder: ['', Validators.required],
        householdMember: this.formBuilder.group({
          adult: ['0'],
          children: ['0']
        }),
        typeOfResidence: ['', Validators.required],
        entranceLocation: ['', Validators.required],
        basementAttic: ['', Validators.required],
        firearmAtHome: ['', Validators.required],
      }),
      anyDog: ['']
    });
  }

  getAllOrganizations() {
    this.organizationService.getAllOrganizations()
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: (response) => {
          this.organizationsList = response.data.rows;
          if (this.organizationsList.length === 1 && Object.keys(this.formData).length == 0) {
            this.userForm.patchValue({
              organizationId: this.organizationsList[0]?.id
            });
            this.getAdvocates(this.userForm.get('organizationId')?.value)
          }
        },
        error: (e) => { }
      });
  }

  getAdvocates(organizationId?: string) {
    this.adminService.getAdvocates({ organizationId, fromAdd: true })
      .pipe(finalize(() => this.loading = false))
      .subscribe({
        next: (response) => {
          this.advocateList = response.data.rows.map((admin: any) => {
            admin.displayName = this.nightwebUtil.getDisplayName(admin?.demographic)
            return admin;
          });
          if (this.advocateList.length === 1) {
            this.userForm.patchValue({
              advocateId: this.advocateList[0]?.id
            });
            return;
          };
          if (this.formData?.advocateId) {
            this.userForm.controls['advocateId'].setValue(this.formData.advocateId);
            return;
          };
          this.userForm.controls['advocateId'].setValue(null);
        },
        error: (e) => { }
      })
  }

  onNavSelected(index: number, elem: string) {
    this.selectedNavIndex = index;
    const yOffset = -100;
    const element = document.querySelector(elem)!;
    const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
    window.scrollTo({ top: y, behavior: 'smooth' })
  }

  save() {
    let user = {
      ...this.userForm.value,
      demographic: {
        ...this.userForm.get('demographic')?.value,
        dob: this.dateUtility.formatDate(this.userForm.get('demographic')?.value.dob, NIGHTWEB_APP_CONSTANT.defaultMomentDateFormat)
      },
      info: {
        ...this.userForm.get('info')?.value,
        perpetratorDob: this.dateUtility.formatDate(this.userForm.get('info')?.value.perpetratorDob, NIGHTWEB_APP_CONSTANT.defaultMomentDateFormat),
        entranceLocation: (this.userForm.get('info')?.value.entranceLocation).join(',')
      }
    }
    
    if (this.userForm.valid) {
      this.loading = true;

      if (this.userForm.get('id')?.value) {
        delete user['status'];
        this.userService.update(user)
          .pipe(finalize(() => { this.loading = false }))
          .subscribe({
            next: (response) => {
              if (response.statusCode == this.status_codes.OK) {
                this.dataEmitter.emit(response.data);
                this.toasterService.show('Success', MESSAGE_CONSTANTS.USER_UPDATED);
              };
            },
            error: (e) => {
            }
          });
      } else {
        this.userService.create(user)
          .pipe(finalize(() => { this.loading = false }))
          .subscribe({
            next: (response) => {
              if (response.statusCode == this.status_codes.OK) {
                this.toasterService.show('Success', MESSAGE_CONSTANTS.USER_ADDED);
                this.dataEmitter.emit(response.data);
              }
            },
            error: (e) => {
            }
          });
      };
    };
  }

  sendData() {
    const data= false;
    this.dataEmitter.emit(data);
  }

  receiveAutocompleteValue(state: any) {
    this.address.controls['home'].patchValue({ state });
  }

  receiveSelectedValue(event: any) {
    this.userForm.patchValue({
      [event.controlName]: event.value
    });
    if (event.controlName === 'organizationId') {
      this.getAdvocates(event.value);
    }
  }

  changeRadioData(type: string, event: any) {
    if(type == 'perpetratorown') {
        this.info.controls['perpetratorOwns'].setValue(event.target.value);
    }else if(type == 'restraining') {
        this.info.controls['activeRestrainingOrder'].setValue(event.target.value);
    }else if(type == 'firearm') {
        this.info.controls['firearmAtHome'].setValue(event.target.value);
    }else{
        this.userForm.controls['anyDog'].setValue(event.target.value);
    }
  }

  onValueChange(event: Event, type: string): void {
    const inputValue = (event.target as HTMLInputElement).value;
    const value = Number(inputValue);
      if (value < 0) {
        if(type == "adult")
          this.householdMember.controls['adult'].setValue(0);
        else
        this.householdMember.controls['children'].setValue(0);
      }
    }
}
