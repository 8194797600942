import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';
import { finalize } from 'rxjs';
import { MESSAGE_CONSTANTS, NIGHTWEB_APP_CONSTANT, STATUS_CODES, US_STATES } from 'src/app/constants';
import { AdminService, OrganizationService, ToasterService, UserService } from 'src/app/services';
import { DateUtility, NightwebUtil } from 'src/app/utils';

@Component({
    selector: 'audio-request-modal',
    templateUrl: './audio-request-modal.component.html',
    styleUrls: ['./audio-request-modal.component.scss']
  })

export class AudioRequestModalComponent implements OnInit {

    audioForm!: FormGroup;

    loading = false;
    submitted = false;

    title!: string;
    formData: any = {};
    dateFormat = NIGHTWEB_APP_CONSTANT.mdbDefaultDateFormat;
    maxDate = new Date();
    emailDisplay = false;
    fromDate: any;
    toDate: any;
    reason: any;
    currDate: any;

    constructor(
        private formBuilder: FormBuilder,
        private userService: UserService,
        private dateUtility: DateUtility,
        private adminService: AdminService,
        private nightwebUtil: NightwebUtil,
        private toasterService: ToasterService,
        private organizationService: OrganizationService,
        public modalRef: MdbModalRef<AudioRequestModalComponent>
      ) {
        this.setAudioForm();
      }

    ngOnInit(): void {
      const today = new Date();
      const month: string = (today.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
      const day: string = today.getDate().toString().padStart(2, '0');
      const year: number = today.getFullYear();
      this.currDate = `${month}/${day}/${year}`;
    }

    setAudioForm() {
      this.audioForm = this.formBuilder.group({
          startDate: ['', Validators.required],
          endDate: ['', Validators.required],
          reasonForRequest: []
      });
    }

    close() {
      this.submitted = false;
      this.modalRef.close();
    }

    save() {
      if (this.audioForm.valid) {
        this.submitted = true;
        this.emailDisplay = true;
        this.fromDate = this.dateUtility.formatDate(this.audioForm.get('startDate')?.value, NIGHTWEB_APP_CONSTANT.defaultMomentDateFormat);
        this.toDate = this.dateUtility.formatDate(this.audioForm.get('endDate')?.value, NIGHTWEB_APP_CONSTANT.defaultMomentDateFormat);
        this.reason = this.audioForm.get('reasonForRequest')?.value;
      }
    }

    sendEmail() {
      const emailData = {
        ...this.formData,
        fromDate: this.fromDate,
        toDate: this.toDate,
        reason: this.reason,
        currDate: this.currDate
      }
      
      this.organizationService.audioRequest(emailData)
          .subscribe((response: any) => {
          if(response.data){
            this.toasterService.show('Success', MESSAGE_CONSTANTS.AUDIO_REQUESTED_SUCCESS);
            this.modalRef.close(response.data);
          }
      });
    }
}