<div class="modal-header shadow-sm">
    <h6 class="modal-title" id="exampleModalLabel">{{ title }}</h6>
    <button type="button" class="btn-close" aria-label="Close" (click)="modalRef.close()"></button>
</div>
<div class="modal-body scrollbar-primary thin">
    <form [formGroup]="configureColumnForm" autocomplete="off">
        <div class="md-form mb-2" *ngFor="let key of keys ">
            <label class="configure-label">
                <input class="configure-checkbox" type="checkbox" [formControlName]="key" />
                <span class="configure-text">{{ getNameByValue(key) }}</span>
            </label>
        </div>
    </form>
</div>
<div class="modal-footer justify-content-between pt-0 bg-gray-lighter">
    <button type="button" class="btn btn-gray-light" (click)="modalRef.close()">{{cancelButtonLabel}}</button>
    <button type="button" class="btn btn-primary d-flex align-items-center" (click)="save()">Save</button>
</div>
