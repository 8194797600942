export const USER_DETAIL_COLUMN = [
    { headerName: 'Emergency', dataName: 'emergency', bodyElement: { value: 'isEmergencyState', dataType: 'boolean' }, enable: true},
	{ headerName: 'Packet Sequence', dataName: 'packetSequence', bodyElement: { value: 'packetSequence', dataType: 'float' }, enable: true},
	{ headerName: 'Watch Check-In', dataName: 'watchCheckIn', bodyElement: { value: 'checkInTime', dataType: 'created' }, enable: true},
	{ headerName: 'Received Check-In', dataName: 'receviedCheckIn', bodyElement: { value: 'createdAt', dataType: 'created' }, enable: true},
	{ headerName: 'Expected Check-In', dataName: 'expectedCheckIn', bodyElement: { value: 'expected', dataType: 'created' }, enable: true},
	{ headerName: 'Deviation', dataName: 'deviation', bodyElement: { value: 'deviation', dataType: 'string' }, enable: true},
	{ headerName: 'GPS', dataName: 'gps', bodyElement: { value: 'latLong' }, enable: true},
	{ headerName: 'Heart Rate', dataName: 'heartRate', bodyElement: { value: 'heartRate', concatString: ' bpm' }, enable: true},
	{ headerName: 'Signal Strength', dataName: 'signalStrength', bodyElement: { value: 'signalStrength', concatString: ' bar' }, enable: true},
	{ headerName: 'LTE Strength', dataName: 'lteStrength', bodyElement: { value: 'lteSignalStrength', dataType: 'lteStrength' }, enable: true},
	{ headerName: 'Battery Status', dataName: 'batteryStatus', bodyElement: { value: 'batteryStatus', dataType: 'percent' }, enable: true},
	{ headerName: 'Charging', dataName: 'charging', bodyElement: { value: 'isCharging', dataType: 'boolean' }, enable: true},
	{ headerName: 'On Wrist', dataName: 'onWrist', bodyElement: { value: 'isOnWrist', dataType: 'boolean' }, enable: false},
]
